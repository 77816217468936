<template>
    <v-container fluid fill-height class="error404">
        <img class="mx-auto" src="../../assets/images/404.svg" alt="403 forbidden"/>
    </v-container>
</template>

<script>
export default {
    name: "Error403"
}
</script>
